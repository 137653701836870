import React, { useRef, useEffect, useState } from 'react';

import { gsap } from "gsap";
import { withRouter } from "react-router-dom";
import { Navbar } from 'react-bootstrap';

import './burger_anim.css';
import './Header.css';

import BurgerMenu from './BurgerMenu.js';

const Header = ({ history }) => {

  const [state, setState] = useState({
    initial: false,
    clicked: null,
    menuName: "close"
  });
  
  const [disabled, setDisabled] = useState(false);
  
  const disableMenu = () => {
    setDisabled(!disabled);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };
   
  useEffect(() => {
    history.listen(() => {
      setState({ clicked: false, menuName: "close" });
    });
  }, [history]);
    
  const handleMenu = () => {
    disableMenu();
    if (state.initial === false) {
      setState({
        initial: null,
        clicked: true,
        menuName: "open"
      });
    } 
    else if (state.clicked === true) {
      setState({
        clicked: !state.clicked,
        menuName: "close"
      });
    } 
    else if (state.clicked === false) {
      setState({
        clicked: !state.clicked,
        menuName: "open"
      });
    }
  };

  const ref = useRef(null);
  
  useEffect(() => {
    const element = ref.current;
      gsap.fromTo(
        element.querySelector(".navbar-brand"),
          {
            y: -70,
            opacity: 1,
          },
            
          {
            delay: 0.9,
            duration: 0.6,
            opacity: 1,
            ease: 'power3.inOut',
            y: 0
          } 
      );
  }, []);

  useEffect(() => {
    const element = ref.current;
      gsap.fromTo(
        element.querySelector(".navbar-toggler"),
          {
            y: -70,
            opacity: 1,
          },
          
          {
            delay: 1.5,
            duration: 0.6,
            opacity: 1,
            ease: 'power3.inOut',
            y: 0
          } 
      );
  }, []);

  return (
    <div ref={ref}> 
      <Navbar className="navbar" collapseOnSelect expand="xxl" bg="navbar navbar-custom" variant="dark">
        <Navbar.Brand className="navbar-brand" href="./">
        <svg width="64" height="24" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0.933105H9.13796C15.8003 0.933105 19.6165 5.89051 19.6165 12.3667C19.6165 18.8428 15.8003 23.7034 9.13796 23.7034H0V0.933105ZM5.43078 19.0718H9.22204C12.7253 19.0718 14.494 15.8576 14.494 12.3667C14.494 8.87572 12.7253 5.56156 9.22204 5.56156H5.43078V19.0718Z" fill="var(--light)"/>
          <path d="M27.0493 8.30081H29.6059V12.7971H27.0493V23.7034H22.3955V12.1745V8.30081V4.79297H27.0493V8.30081Z" fill="var(--light)"/>
          <path d="M43.3498 8.30082H48.3867V23.7034H43.3498V21.951C42.3782 23.3068 40.7387 24.0001 39.0323 24.0001C35.1818 24.0001 31.4326 20.9933 31.4326 15.9698C31.4326 10.9801 35.1818 7.90576 39.0323 7.90576C40.6048 7.90576 42.3782 8.60057 43.3498 9.95483V8.30082ZM39.7905 19.5684C40.505 19.5687 41.2036 19.3598 41.7978 18.9681C42.392 18.5764 42.8552 18.0195 43.1289 17.3679C43.4025 16.7163 43.4743 15.9992 43.3351 15.3073C43.1959 14.6154 42.852 13.9798 42.3469 13.4809C41.8417 12.982 41.1981 12.6422 40.4974 12.5045C39.7966 12.3667 39.0702 12.4373 38.4101 12.7072C37.75 12.977 37.1857 13.4341 36.7887 14.0206C36.3918 14.6071 36.1799 15.2967 36.1799 16.0021C36.1868 16.9456 36.5694 17.8484 37.245 18.5157C37.9206 19.183 38.8349 19.5611 39.7905 19.5684Z" fill="var(--light)"/>
          <path d="M56.258 8.30273H51.4033V23.7037H56.258V8.30273Z" fill="var(--light)"/>
          <path d="M56.258 0H51.4033V4.79293H56.258V0Z" fill="var(--yellow)"/>
          <path d="M59.1455 0H64.0002V23.7033H59.1455V0Z" fill="var(--light)"/>
        </svg>
        </Navbar.Brand>
          <button disabled={disabled} onClick={handleMenu} className="navbar-toggler">
            <svg width="24px" height="24px" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect className={state.menuName} x="0" y="0" width="24" height="4" fill="var(--light)" stroke="var(--light)"/>
              <rect className={state.menuName} x="20" y="8" width="4" height="4" fill="var(--light)" stroke="var(--light)"/>
              <rect className={state.menuName} x="0" y="16" width="24" height="4" fill="var(--light)" stroke="var(--light)"/>
            </svg>
          </button>
      </Navbar>  
        <BurgerMenu state={state} />
    </div>
  )
}
export default withRouter(Header);