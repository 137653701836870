import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './SyncData.css';

import { gsap } from "gsap";

import Data from '../icons/data.svg'
import Attachments from '../icons/attachments.svg'

const SyncData= () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".reasons-container",
                start: "center center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.depletion-image', {xPercent:-150, ease:"power3.inOut"}, {xPercent:0, ease:"power3.inOut", duration: 1.2},)
            tl.fromTo('.reasons-header-first', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .6)
            tl.fromTo('.reasons-text-description-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1.4 }, .8)
                       
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".reasons-container",
                start: "bottom center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            
            tl.fromTo('.waste-image', {xPercent:-150, ease:"power3.inOut"}, {xPercent:0, ease:"power3.inOut", duration: 1.2},)
            tl.fromTo('.reasons-header-second', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .6)
            tl.fromTo('.reasons-text-description-second',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1.4 }, .8)
            
    }, []);

    return (
        <div className="reasons-container">
            <Container>
                <Row>
                    <Col className="reasons-container-inner" lg={{ span: 5 }}>
                        <img className="reasons-image depletion-image" src={Data} alt="data"/>
                        <div className="reasons-header-container"><h2 className="reasons-header reasons-header-first">Sync product data</h2></div>
                        <p className="reasons-text-description-first">Sync product data in the Dtail Catalog or plan and create placeholders at any time.</p>
                    </Col>
                    <Col className="reasons-container-inner" lg={{ span: 5, offset: 2 }}>
                        <img className="reasons-image waste-image" src={Attachments} alt="attachments"/>
                        <div className="reasons-header-container"><h2 className="reasons-header reasons-header-second">Marketing content</h2></div>
                        <p className="reasons-text-description-second">Add inspirational content to support product or brand storytelling.</p>
                    </Col>
                </Row>
            </Container>
        </div> 
    )
}
export default SyncData;