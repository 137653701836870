import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import { gsap } from "gsap";

import './Contacts.css';
import Arrow from "../icons/arrow.svg"

function Contacts() {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".contacts-content",
                start: "top bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.header', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, })
            tl.from('.contacts-container', { opacity:0, ease:"power3.inOut", duration: 1 }, .6)
            tl.from('#line', { width:0, ease:"ease.inOut", duration: 1.2 }, .8)  
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".contacts-container",
                start: "bottom bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.action-text-first', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .2)
            tl.fromTo('.action-arrow-first', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .2)
            tl.fromTo('.action-button-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)
            tl.fromTo('.action-text-second', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, },.4)
            tl.fromTo('.action-arrow-second', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .4)
            tl.fromTo('.action-button-text-second',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)
    }, []);

    return (
        <div className="contacts-content"> 
            <Container>
                <Row>
                    <Col align="center" className="contacts-header">
                        <h1 className="header">Contact us</h1>
                    </Col>
                </Row>
                <Row className="contacts-container">
                    <Col align="center" className="contacts-container-inner" sm={12} md={6}>
                        <h3>Dtail EU (HQ)</h3>
                        <p className="contacts-address"> Spaarneplein 2 <br />
                            2515 VK Den Haag <br />
                            The Netherlands
                        </p>
                        <h3 className="contacts-telephone">
                            +31 70 4451220
                        </h3>
                    </Col>
                    <Col sm={12} align="center" className="contacts-three-dots"><p>• • •</p></Col>
                    <Col align="center" className="contacts-container-inner" sm={12} md={6}>
                        <h3>Dtail US</h3>
                        <p className="contacts-address">621 SW Alder Street <br />
                            97205 Portland, OR <br />
                            United States
                        </p>
                        <h3 className="contacts-telephone">
                            +1 503 2783565
                        </h3>
                    </Col>
                </Row>
                <Row><hr size="1" width="100%" color="white" id="line"></hr></Row>
                <Row className="action-container">
                    <Col align="center" md={12} lg={6} className="action-container-inner">
                        <div className="action-text-container"><h3 className="action-text action-text-first">We would like to hear from you.</h3></div>
                        <Button className="button" href="mailto:inquiries@pixelpool.com"><span className="action-button-text-first">Get in touch</span><img src={Arrow} className="arrow action-arrow-first" alt=""></img></Button>
                    </Col>
                    <Col align="center" md={12} lg={6} className="action-container-inner">
                        <div className="action-text-container"><h3 className="action-text action-text-second">We are hiring!</h3></div>
                        <Button className="button" href="https://www.linkedin.com/jobs/search?keywords=pixelpool&location=Netherlands&geoId=102890719&trk=public_jobs_jobs-search-bar_search-submit&position=1&pageNum=0&lipi=urn%3Ali%3Apage%3Ad_flagship3_detail_base%3BNzGpcFuLRE6qOLpLESreMw%3D%3D"><span className="action-button-text-second">Discover</span><img src={Arrow} className="arrow action-arrow-second" alt=""></img></Button>
                    </Col>
                </Row> 
            </Container>
        </div>   
    )
}
export default Contacts;