import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './PlanDesign.css';

import { gsap } from "gsap";

import DesignerMedia from "../images/Digital product creation.png"
import Arrow from '../icons/arrow.svg'

const PlanDesign= () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".plan-content",
                start: "center center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.plan-image-background', {xPercent:-100, ease:"power3.inOut"}, {xPercent:0, ease:"power3.inOut", duration: 1.2},)
            tl.fromTo('.plan-image', {xPercent:-150, ease:"power3.inOut"}, {xPercent:0, ease:"power3.inOut", duration: 1.2}, .4)
            
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".plan-container-layer-text",
                start: "bottom center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.plan-small-header-text', {x:-150, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2},)
            tl.fromTo('.plan-rectangle', {x:-150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {x:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .6)
            tl.fromTo('.plan-header', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .6)
            tl.fromTo('.plan-text-description',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.4)
            tl.fromTo('.plan-arrow', {x:-210, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, 1)
            tl.fromTo('.plan-button-name',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.6)
            
    }, []);

    return (
    <div className="plan-content">  
        <Container>
        <Row className="plan-container-layer">
            <Col align="center" md={12} lg={6} className="plan-container-layer-media">
                <img className="plan-image" src={DesignerMedia} alt="3D clothing"/>
            </Col>
                <Col md={12} lg={{ span: '5', offset: '1' }} className="plan-container-layer-text">
                    <Row>
                        <Col className="plan-small-header">
                            <svg className="plan-rectangle" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" fill="var(--yellow)"/>
                            </svg>
                            <h4 className="plan-small-header-text">Plan & Design</h4>
                        </Col>   
                    </Row>
                    <Row>
                        <Col className="plan-header-text">
                            <h2 className="plan-header">Dtail Designer</h2> 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="plan-text-description">Turn design concepts into new 3D samples without requiring 3D design software by using Dtail Designer.</p>
                        </Col> 
                    </Row>
                    <Row>
                        <Col>
                            <Button className="button" href="./contacts"><span className="plan-button-name">Schedule a demo</span><img src={Arrow} className="plan-arrow arrow" alt=""></img></Button>
                        </Col>  
                    </Row>
                </Col>
            </Row>     
        </Container>
        <div className="plan-image-background"> </div>
    </div>   
    )
}
export default PlanDesign;