import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Reasons.css';

import { gsap } from "gsap";

import Arrow from '../icons/arrow.svg'

import Carbon from '../icons/carbon.svg'
import Depletion from '../icons/depletion.svg'
import Pollution from '../icons/pollution.svg'
import Waste from '../icons/waste.svg'

const ReasonsPage = () => {
    
    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".reasons-container",
                start: "center bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.reasons-main-header', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, })
            tl.fromTo('.reasons-main-text-description',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)
            tl.fromTo('.action-arrow-first', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .8)
            tl.fromTo('.action-button-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.4)  
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".reasons-container-inner-second",
                start: "top center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.depletion-image',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 },)
            tl.fromTo('.reasons-header-first', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .2)
            tl.fromTo('.reasons-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1.2 }, .4)  

            tl.fromTo('.waste-image',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)
            tl.fromTo('.reasons-header-second', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1, skewY: 0, }, 1.2)
            tl.fromTo('.reasons-text-second',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1.2 }, 1.4) 
    }, []);


    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".reasons-container-inner-second",
                start: "bottom center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.pollution-image',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 },)
            tl.fromTo('.reasons-header-third', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .2)
            tl.fromTo('.reasons-text-third',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1.2 }, .4)  

            tl.fromTo('.carbon-image',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)
            tl.fromTo('.reasons-header-fourth', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1, skewY: 0, }, 1.2)
            tl.fromTo('.reasons-text-fourth',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1.2 }, 1.4) 
    }, []);
    
    return (
        <div className="reasons-container">
            <Container>
                <Row>
                    <Col align="center" className="reasons-main-text">
                        <div className="reasons-main-header-container"><h2 className="reasons-main-header">Go virtual to drive sustainability practices</h2></div>
                        <p className="reasons-main-text-description">Lower your dependency on physical samples and showroom offices, thereby contribute to reducing resource depletion, water & air pollution, waste and your carbon footprint in the fashion industry.</p>
                        <Button className="reasons-main-button button" href="./contacts"><span className="action-button-text-first"> Join the movement</span><img src={Arrow} className="arrow action-arrow-first" alt=""></img></Button>
                    </Col>
                </Row>
                <Row className="reasons-container-inner-first">
                    <Col className="reasons-container-inner" lg={{ span: 5 }}>
                        <img className="reasons-image depletion-image" src={Depletion} alt="depletion"/>
                        <div className="reasons-header-container reasons-header"><h2 className="reasons-header-first">Resource depletion</h2></div>
                        <p className="reasons-text-first">Fashion depletes limited natural resources – it consumes 79 billion cubic metres of water, and uses 31 billion litres of crude oil, enough to fill 12,402 Olympic pools.</p>
                    </Col>
                    <Col className="reasons-container-inner" lg={{ span: 5, offset: 2 }}>
                        <img className="reasons-image waste-image" src={Waste} alt="waste"/>
                        <div className="reasons-header-container reasons-header"><h2 className="reasons-header-second">Waste</h2></div>
                        <p className="reasons-text-second">The industry and garment end- of-use generates 92 million tonnes of waste, 80 per cent of which is primarily disposed of in landfill.</p>
                    </Col>
                </Row>
                <Row className="reasons-container-inner-second">
                    <Col className="reasons-container-inner" lg={{ span: 5 }}>
                        <img className="reasons-image pollution-image" src={Pollution} alt="pollution"/>
                        <div className="reasons-header-container reasons-header"><h2 className="reasons-header-third">Water & air pollution</h2></div>
                        <p className="reasons-text-third">43 million tonnes of chemicals are used to produce textiles each year which can pollute the water when released as waste products into the environment.</p>
                    </Col>
                    <Col className="reasons-container-inner" lg={{ span: 5, offset: 2 }}>
                        <img className="reasons-image carbon-image" src={Carbon} alt="carbon"/>
                        <div className="reasons-header-container reasons-header"><h2 className="reasons-header-fourth">Carbon footprint</h2></div>
                        <p className="reasons-text-fourth">Each year the fashion industry releases 1.75 billion tonnes of carbon dioxide in producing and transporting the garments. Besides, the total emissions of the travel associated with the ready-to-wear wholesale buying process is: 241,000 tCO2e; enough energy to light up Times Square for 58 years or the Eiffel Tower for 3,060 years</p>
                    </Col>
                </Row>
            </Container>
        </div> 
    )
}
export default ReasonsPage;