import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Comparison.css';

import Comparison from "../images/Sustainable fashion chain.svg"
import ComparisonMobile from "../images/Sustainable fashion chain_mobile.svg"

function ComparisonPage() {
    return (
        <div className="comparison-content"> 
            <Container>
                <Row className="comparison-content-container">
                    <Col align="center">
                        <img className="comparison-image" src={Comparison} alt="Comparison"/>
                        <img className="comparison-image-mobile" src={ComparisonMobile} alt="ComparisonMobile"/>
                    </Col>
               </Row>
            </Container>
        </div>   
    )
}
export default ComparisonPage;