import React, { useRef, useEffect } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './Components/fonts/Sofia Pro Bold Az.otf';
import './Components/fonts/Sofia Pro Semi Bold Az.otf';
import './Components/fonts/Sofia Pro Medium Az.otf';
import './Components/fonts/Sofia Pro Regular Az.otf';

import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";
import Mission from "./Components/Mission/Mission";
import WorkflowStart from "./Components/Workflow/Workflow";
import PlanDesign from "./Components/PlanDesign/PlanDesign";
import Canvas from "./Components/Canvas/Canvas";
import SyncData from "./Components/SyncData/SyncData";
import Prepare from "./Components/Prepare/Prepare";
import Brand from "./Components/Sell/Sell";
import Architecture from "./Components/Architecture/Architecture";
import SustainabilityPage from "./Components/Sustainability/Sustainability";
import ComparisonPage from "./Components/Comparison/Comparison";
import ReasonsPage from "./Components/Reasons/Reasons";
import Contacts from "./Components/Contacts/Contacts";
import ScrollToTop from "./ScrollToTop";

function App() {

  return (
    <Router>
      <ScrollToTop />
      <div className='App'>
        <div className='Header'><Header /></div>
           <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/workflow' component={Workflow} />
              <Route exact path='/platform' component={Platform} />
              <Route exact path='/sustainability' component={Sustainability} />
              <Route exact path='/contacts' component={ContactUs} />
            </Switch>
      </div>
    </Router>
  );
}


function Home() {

  gsap.registerPlugin(ScrollTrigger);
    const ref = useRef(null);
      useEffect(() => {
        const element = ref.current;
        gsap.fromTo(
        element.querySelector(".Pin"),
          {
            opacity: 1,
            y: 0
          },
          
          {
            opacity: 1,
            y: 0,
            scrollTrigger: {
              markers: false,
              trigger: ".Pin",
              pin: true, 
              pinSpacing: false, 
              start: "top",
              end: "bottom",
              scrub: true
            }
          }
        );
      },[]);

  return (
    <div ref={ref}>
      <div className="Pin"><Main /></div>
      <div><Mission /></div>
    </div>
  );
}

function Workflow() {
  return (
    <div>
      <div><WorkflowStart /></div>
      <div><Canvas /></div>
      <div><PlanDesign /></div>
      <div><SyncData /></div>
      <div><Prepare /></div>
      <div><Brand /></div>
    </div>
  );
}

function Platform() {
  return (
    <Architecture />
  );
}

function Sustainability() {
  return (
    <div>
    <SustainabilityPage />
    <ComparisonPage />
    <ReasonsPage />
    </div>
  );
}

function ContactUs() {
  return (
    <Contacts />
  );
}

export default App;
