import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Architecture.css';

import { gsap } from "gsap";

import ArchitectureImage from "../images/Fashion platform architecture.svg"

import Cube from '../icons/cube.svg'
import Cloud from '../icons/cloud.svg'
import PlatformIcon from '../icons/platform.svg'
import Arrow from '../icons/arrow.svg'

const Architecture = () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".architecture-content",
                start: "top bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.architecture-header', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, })
            tl.fromTo('.architecture-subheader-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .6)
            tl.fromTo('.architecture-three-dots', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .4)
            tl.fromTo('.architecture-text-second', { opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)
            tl.from('#line', { width:0, ease:"ease.inOut", duration: 1.2 }, .6)
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".architecture-container-layer",
                start: "center bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.architecture-image', { opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 })
            
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".architecture-container-layer-text",
                start: "top center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.cloud-header', {y:100, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .2)
            tl.fromTo('.cloud', {y:-100, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .2)
            tl.fromTo('.cloud-description',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .6)
            
            tl.fromTo('.catalog-header', {y:100, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .6)
            tl.fromTo('.platform-icon', {y:-100, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .6)
            tl.fromTo('.catalog-description',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)

            tl.fromTo('.spaces-header', {y:100, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, 1)
            tl.fromTo('.architecture-cube', {y:-100, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, 1)
            tl.fromTo('.spaces-description',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.4)
            
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".architecture-text-description-last",
                start: "top center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.from('#second-line', { width:0, ease:"ease.inOut", duration: 1.2 },)
            tl.fromTo('.action-text-first', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .2)
            tl.fromTo('.action-arrow-first', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .2)
            tl.fromTo('.action-button-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)        
    }, []);

    return (
        <div className="architecture-content"> 
            <Container>
                <Row>
                    <Col align="center" className="architecture-header-container">
                        <h1 className="architecture-header">Platform Architecture</h1>
                    </Col>
                </Row>
                <Row className="architecture-container">
                    <Col lg={{ span: 12 }} className="architecture-media-container" align="center">
                        <p className="architecture-subheader-text-first"> Dtail is built API-first.</p>
                        <Col sm={12} align="center" className="three-dots"><p  className="architecture-three-dots">• • •</p></Col>
                        <p className="architecture-text-second">Integrate with PLM, DAM, ERP and order intake systems to fit any existing IT infrastructure.</p>
                    </Col>
                </Row>
                <Row><hr size="1" width="100%" color="white" id="line"></hr></Row>
                <Row className="architecture-container-layer">
                    <Col align="center" md={12} lg={6} className="architecture-container-layer-media">
                        <img className="architecture-image" src={ArchitectureImage} alt="ArchitectureImage"/>
                    </Col>
                    <Col md={12} lg={6} className="architecture-container-layer-text">
                        <Row>
                            <Col>
                                <h2 className="architecture-text"><img src={Cloud} className="cloud" alt=""></img><span className="cloud-header">Cloud</span></h2>
                                <p className="architecture-text-description"><span className="cloud-description">We store the data securely and keep your content in our apps up to date.</span></p>
                            </Col>   
                        </Row>
                        <Row>
                            <Col>
                                <h2 className="architecture-text"><img src={PlatformIcon} className="platform-icon" alt=""></img><span className="catalog-header">Catalog</span></h2>
                                <p className="architecture-text-description catalog-description">Plan and visualize your collections by linking product data to 3D samples.</p>
                            </Col>   
                        </Row>
                        <Row>
                            <Col>
                                <h2 className="architecture-text"><img src={Cube} className="architecture-cube" alt=""></img><span className="spaces-header">Spaces</span></h2>
                                <p className="architecture-text-description-last spaces-description">Use your 3D samples in realistic, fully customizable virtual showrooms and elevate visual merchandising and B2B sales processes of your brand.</p>
                            </Col>   
                        </Row>
                    </Col>
                </Row> 
                <Row><hr size="1" width="100%" color="white" id="second-line"></hr></Row>
                <Col align="center" md={12} lg={12} className="action-container-inner">
                <div className="action-text-container"><h3 className="action-text action-text-first">Request a demo</h3></div>
                <Button className="button" href="./contacts"><span className="action-button-text-first">Request</span><img src={Arrow} className="arrow action-arrow-first" alt=""></img></Button>
            </Col> 
            </Container>
        </div>   
    )
}
export default Architecture;