import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { gsap } from "gsap";

import './Workflow.css';

import LineDesktop from '../images/Clothing supply chain.svg'
import LineTablet from '../images/Clothing supply chain_tablet.svg'

const WorkflowStart = () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".workflow-content",
                start: "top bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.header', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, })
            tl.fromTo('.first-paragraph',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .6)
            tl.fromTo('.workflow-three-dots', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .4)
            tl.fromTo('.second-paragraph', { opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)
            tl.fromTo('.workflow-image', { opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)
    }, []);

    return (
        <div className="workflow-content">   
            <Container>
                <Row>
                    <Col className="workflow-header">
                        <h1 className="header">Take control</h1>
                    </Col>
                </Row>
                <Row> 
                    <Col className="workflow-text-container">
                        <p className="first-paragraph workflow-text">Dtail transforms workflows by allowing fashion brands to utilize 3D samples and environments in key phases in the value chain.</p>
                        <Col sm={12} align="center" className="three-dots"><p className="workflow-three-dots">• • •</p></Col>
                        <p className="second-paragraph workflow-text">Move away from expensive outsourced one off builds. Instead, take control and invest in a scalable solution that improves your daily workflows - day after day, season after season.</p>
                    </Col>
                </Row>
                <Row>
                    <Col align="center" className="workflow-image">
                        <img className="workflow-image-desktop" src={LineDesktop} alt="LineImage"/>
                        <img className="workflow-image-tablet" src={LineTablet} alt="LineImage"/>
                    </Col>
                </Row>
            </Container>
        </div>   
    )
}
export default WorkflowStart;