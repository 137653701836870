import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Sustainability.css';

import { gsap } from "gsap";

import SustainabilityDress from "../images/3D apparel visualization.jpg"

const SustainabilityPage = () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".sustainability-content",
                start: "top bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.sustainability-header-first', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, })
            tl.fromTo('.sustainability-header-second', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, },.2)
            tl.fromTo('.sustainability-header-third', {y:200, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .3)
            tl.fromTo('.sustainability-header-description',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)
            tl.fromTo('.sustainability-image',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".sustainability-content-text-container",
                start: "center bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.sustainability-content-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 })
            tl.fromTo('.sustainability-content-text-second',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .2)
    }, []);

    return (
        <div className="sustainability-content"> 
            <Container>
                <Row className="sustainability-container-layer">
                    <Col>
                        <div className="sustainability-header-container"><h1 className="sustainability-header-first">Drive</h1></div>
                        <div className="sustainability-header-container"><h1 className="sustainability-header-second">sustainability</h1></div>
                        <div className="sustainability-header-container sustainability-header"><h1 className="sustainability-header-third">practices</h1></div>
                        <p className="sustainability-header-description">We envision a future with a sustainable fashion industry with no sample waste in the value chain.</p>
                    </Col>
                    <Col md={12} lg={6}>
                        <img className="sustainability-image" src={SustainabilityDress} alt="3D fashion"/>
                    </Col>
                </Row>
                <Row className="sustainability-content-text-container">
                    <Col lg={{ span: 5 }}>
                    <p className="sustainability-content-text-first">By using the power of 3D technologies, we contribute to your company’s sustainability goals and helps promote a healthier planet with less pollution and waste.</p>
                    </Col>
                    <Col align="center" className="sustainability-dots three-dots"><p>• • •</p></Col>
                    <Col lg={{ span: 5, offset: 2 }}>
                    <p className="sustainability-content-text-second">Dtail empowers fashion brands to move away from physical samples, offices and the need to travel, transforming the way you operate from the plan to sell stage.</p>
                    </Col>
                </Row>
            </Container>
        </div>   
    )
}
export default SustainabilityPage;