import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import './Prepare.css'

import { gsap } from "gsap";

import PrepareMedia from "../images/Virtual Showroom.png"
import Arrow from '../icons/arrow.svg'

const PlanDesign= () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".prepare-content",
                start: "center center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.prepare-container-layer', {yPercent:100, ease:"power3.inOut"}, {yPercent:0, ease:"power3.inOut", duration: 1.8},)
            tl.fromTo('.prepare-image', {xPercent:150, ease:"power3.inOut"}, {xPercent:0, ease:"power3.inOut", duration: 1.8}, .1)
            
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".prepare-container-layer",
                start: "top bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.prepare-small-header-text', {x:-150, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .4)
            tl.fromTo('.prepare-rectangle', {x:-150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {x:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .6)
            tl.fromTo('.prepare-header', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .8)
            tl.fromTo('.prepare-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.4)
            tl.fromTo('.prepare-text-second',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.6)
            tl.fromTo('.prepare-text-third',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.8)
            tl.fromTo('.prepare-arrow', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, 1.8)
            tl.fromTo('.prepare-button-name',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 2.2)
            
    }, []);

    return ( 
        <div className="prepare-content"> 
            <Container>
                <Row className="prepare-container-layer">
                    <Col md={12} xs={{ order: 'last' }} sm={{ order: 'last' }} lg={{ span: '6', order: 'first' }} className="prepare-container-layer-text">
                        <Row>
                            <Col className="prepare-small-header">
                                <svg className="prepare-rectangle" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" fill="var(--yellow)"/>
                                </svg>
                                <h4 className="prepare-small-header-text">Prepare</h4>
                            </Col>   
                        </Row>
                        <Row>
                            <Col className="prepare-header-text">
                                <h2 className="prepare-header">Dtail Spaces</h2> 
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="prepare-text-description prepare-text-first">Plan assortments for different customer segments, accounts or store clusters in the Catalog.</p>
                            </Col>   
                        </Row>
                        <Row>
                        </Row>
                        <Row>
                            <Col>
                                <p className="prepare-text-description prepare-text-second">Discover endless ways to merchandise assortments in state of the art branded 3D environments to support VM & sell in.</p>
                            </Col>   
                        </Row>
                        <Row>
                        </Row>
                        <Row>
                            <Col>
                                <p className="prepare-text-description prepare-text-third">Create unlimited amounts of floor plans and fixture variations with unrivalled degree of control and flexibility.</p>
                            </Col>   
                        </Row>
                        <Row>
                        <Col>
                            <Button className="button" href="./platform"><span className="prepare-button-name">Watch how it works</span><img src={Arrow} className="prepare-arrow arrow" alt=""></img></Button>
                        </Col>  
                    </Row>
                    </Col>
                    <Col md={12} lg={6} align="right" className="prepare-container-layer-media">
                    <img className="prepare-image" src={PrepareMedia} alt="Virtual Showroom"/>
                    </Col>
                </Row>        
            </Container>
        </div>    
    )
}
export default PlanDesign;