import React, { useRef, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';

import { NavLink } from 'react-router-dom';
import { gsap } from "gsap";

import "./BurgerMenu.css";

const BurgerMenu = ({ state }) => {
         
    let menu = useRef(null);
    let revealMenu = useRef(null);
    let revealMenuBackground = useRef(null);

    let line1 = useRef(null);
    let line2 = useRef(null);
    let line3 = useRef(null);
    let line4 = useRef(null);
    let line5 = useRef(null);


    useEffect(() => {
        if (state.clicked === false) {
            gsap.to([revealMenu, revealMenuBackground], {
                duration: 0.8,
                height: 0,
                ease: 'power3.inOut',
                stagger: {
                    amount: .1
                }
            });

            gsap.to(menu, {
                duration: 1,
                css:{ display: 'none' }
            });
        } 
        
        else if (
            state.clicked === true ||
            (state.clicked === true && state.initial === null)
        )
        {
            gsap.to(menu, {
                duration: 0,
                css:{ display: 'block' }
            });
            
            gsap.to([revealMenu, revealMenuBackground], {
                duration: 0,
                opacity: 1,
                height: "100%",
            });
            
            staggerReveal(revealMenuBackground, revealMenu);
            staggerText(line1, line2, line3, line4, line5);
        }
    }, [state]);

    const staggerReveal = (node1, node2) => {
        gsap.from ([node1, node2 ], {
            duration: 0.8,
            height: 0,
            transformOrigin: 'right top',
            skewY: 2,
            ease: 'power3.inOut',
            stagger: {
                amount: 0.1
            }
        });
    };

    const staggerText = (node1, node2, node3, node4, node5) => {
        gsap.from ([node1, node2, node3, node4, node5 ], {
            duration: 0.8,
            y: -300,
            dealy: .2,
            ease: 'power3.inOut',
            stagger: {
                amount: 0.2
            }
        });
    };

    return (
        <div ref={el => (menu = el)} className="menu-container">
            <div ref={el => (revealMenuBackground = el)} className="menu-secondary-background"></div>
            <div ref={el => (revealMenu = el)} className="menu-layer">
            <Container>
                <Row className="menu-content" align="center">
                    <Col className="pagenames-container">
                        <nav>
                            <ul className="pagenames">
                                <li>
                                    <NavLink to='/' exact activeStyle={{ color: "var(--light)" }}>
                                        <h1 ref={el => (line1 = el)}>Home</h1>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/workflow' exact activeStyle={{ color: "var(--light)" }}>
                                        <h1 ref={el => (line2 = el)}>Workflow</h1>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/platform' exact activeStyle={{ color: "var(--light)" }}>
                                        <h1 ref={el => (line3 = el)}>Platform</h1>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/sustainability' exact activeStyle={{ color: "var(--light)" }}>
                                        <h1 ref={el => (line4 = el)}>Sustainability</h1>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to='/contacts' exact activeStyle={{ color: "var(--light)" }}>
                                        <h1 ref={el => (line5 = el)}>Contact Us</h1>
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </Container>
            </div>
        </div>
    )
}

export default BurgerMenu;
