import React, { useEffect, useRef } from 'react';

import { Container } from 'react-bootstrap';
import { gsap } from "gsap";

import './Main.css';

import Video from '../videos/showcase.mp4';


function Main() {
    const ref = useRef(null);


        useEffect(() => {
            const element = ref.current;
            gsap.fromTo(
            element.querySelector(".main-header-first"),
            {
                y: 340,
                transformOrigin: 'right top',
                skewY: 8,
                opacity: 0,
                stagger: {
                    amount: 1.4
                }
            },
            {
                delay: 0.9,
                duration: 1.2,
                opacity: 1,
                ease: 'power3.inOut',
                y: 0,
                skewY: 0,
                }
            
            );
        },
        
        []);

        useEffect(() => {
            const element = ref.current;
            gsap.fromTo(
            element.querySelector(".main-header-second"),
            {
                y: 340,
                opacity: 0,
                transformOrigin: 'right top',
                skewY: 8,
                stagger: {
                    amount: 1.4
                }
            },
            {
                delay: 1.1,
                duration: 1.2,
                ease: 'power3.inOut',
                y: 0,
                opacity: 1,
                skewY: 0,
                }
            
            );
        },
        
        []);
        
        useEffect(() => {
            const element = ref.current;
            gsap.fromTo(
            element.querySelector(".main-text-description"),
            {
                
                transformOrigin: 'right top',
                opacity: 0,
            },
            {
                delay: 1.6,
                duration: 1.2,
                opacity: 1,
                ease: 'power3.inOut',

                }
            
            );
        },
        
        []);

        useEffect(() => {
            const element = ref.current;
            gsap.fromTo(
            element.querySelector(".video-background-color"),
            {   
                width: "-100%",
                x: "-100vw",
                transformOrigin: 'right top',
                skewX: 0,
                stagger: {
                    amount: 0.05
                }
            },
            {
                width: "0%",
                skewX: 0,
                x: "100vw",
                duration: 2,
                ease: 'power3.inOut',
                }
            
            );
        },
        
        []);

        useEffect(() => {
            const element = ref.current;
            gsap.fromTo(
            element.querySelector(".video-container"),
            {
                
                width: "0%"
            },
            {
                width: "100%",
                delay: 0.2,
                duration: 1.7,
                ease: 'power3.inOut',
                }
            
            );
        },
        
        []);


        return (
        <div className="main-content" ref={ref}>
            <div className="video-background-color"></div>
            <video className="video-container" loop autoPlay muted playsInline> <source src={Video}  type="video/mp4"/></video>
            <Container>
                <div className="main-text">
                    <div className="main-header-container">
                        <div className="main-header-container-first"><div className="main-header-first"><h1>Maximize</h1></div></div> 
                        <div className="main-header-container-second"><div className="main-header-second"><h1>the value of 3D</h1></div></div>
                    </div>
                    <p className="main-text-description">Dtail helps fashion brands to visually plan, analyze and sell their collections by providing a 3D and cloud based go to market platform.</p>
                </div>
            </Container>
        </div>   
        )
}
    
export default Main;