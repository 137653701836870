import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import './Canvas.css'

import { gsap } from "gsap";

import CanvasMedia from "../images/Fashion data visualization.png"
import Arrow from '../icons/arrow.svg'

const Canvas= () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".canvas-content",
                start: "top center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.canvas-image-background', {xPercent:100, ease:"power3.inOut"}, {xPercent:0, ease:"power3.inOut", duration: 1},)
            tl.fromTo('.canvas-image-background-mobile', {xPercent:100, ease:"power3.inOut"}, {xPercent:0, ease:"power3.inOut", duration: 1},)
            tl.fromTo('.canvas-image', {yPercent:-100, ease:"power3.inOut"}, {yPercent:0, ease:"power3.inOut", duration: 1.2}, .4)
            
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".canvas-container-layer-text",
                start: "center center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.canvas-small-header-text', {x:-150, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2},)
            tl.fromTo('.canvas-rectangle', {x:-150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {x:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .6)
            tl.fromTo('.canvas-header', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .6)
            tl.fromTo('.canvas-text-description',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.4)
            tl.fromTo('.canvas-arrow', {x:-130, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, 1)
            tl.fromTo('.canvas-button-name',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.6)
            
    }, []);

    return ( 
        <div className="canvas-content">  
            <Container>
                <Row className="canvas-container-layer">
                    <Col md={12} xs={{ order: 'last' }} sm={{ order: 'last' }} lg={{ span: '6', order: 'first' }} className="canvas-container-layer-text">
                        <Row>
                            <Col className="canvas-small-header">
                                <svg className="canvas-rectangle" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" fill="var(--yellow)"/>
                                </svg>
                                <h4 className="canvas-small-header-text">Plan & Design</h4>
                            </Col>     
                        </Row>
                        <Row>
                            <Col className="canvas-header-text">
                                <h2 className="canvas-header">Canvas</h2> 
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="canvas-text-description">Review, refine and validate assortments on KPI’s and visual appeal on the Canvas.</p>
                            </Col>   
                        </Row>
                        <Row>
                            <Col>
                                <Button className="button" href="./contacts"><span className="canvas-button-name">Get Dtail</span><img src={Arrow} className="canvas-arrow arrow" alt=""></img></Button>
                            </Col>  
                        </Row>
                    </Col>   
                    <Col align="left" md={12} lg={6} className="canvas-container-layer-media">
                        <div className="canvas-image-background-mobile"></div>
                        <img className="canvas-image" src={CanvasMedia} alt="Virtual Fashion"/>
                    </Col>
                </Row>    
            </Container>
            <div className="canvas-image-background"> </div>
        </div>    
    )
}
export default Canvas;