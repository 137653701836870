import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './Sell.css';

import { gsap } from "gsap";

import SellMedia from "../images/Digital environment.png"
import Arrow from '../icons/arrow.svg'

const Brand = () => {

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".sell-content",
                start: "center bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.sell-image-background', { width:0, ease:"power3.inOut"}, { width:"100%", ease:"power3.inOut", duration: 1.8},)
            tl.fromTo('.sell-image', {yPercent:150, ease:"power3.inOut"}, {yPercent:0, ease:"power3.inOut", duration: 1.8},.2)
            tl.fromTo('.sell-small-header-text', {x:-150, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .4)
            tl.fromTo('.sell-rectangle', {x:-150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {x:0, ease:"power3.inOut", duration: 1, skewY: 0, }, .6)
            tl.fromTo('.sell-header', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .8)
            tl.fromTo('.sell-text-description-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.4)
            tl.fromTo('.sell-text-description-second',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1.6)
            tl.fromTo('.sell-arrow', {x:-150, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, 1.6)
            tl.fromTo('.sell-button-name',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 2)         
    }, []);

    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".sell-container-layer",
                start: "bottom center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.from('#line', { width:0, ease:"ease.inOut", duration: 1.2 },)
            tl.fromTo('.action-text-first', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .2)
            tl.fromTo('.action-arrow-first', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .2)
            tl.fromTo('.action-button-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)
    }, []);

    return (
        <div className="sell-content">  
        <Container>
        <Row className="sell-container-layer">
            <Col align="center" md={12} lg={6} className="sell-container-layer-media">
                <div className="sell-image-background"> </div>
                <img className="sell-image" src={SellMedia} alt="Digital Showroom"/>
            </Col>
                <Col md={12} lg={{ span: '5', offset: '1' }} className="sell-container-layer-text">
                    <Row>
                        <Col className="sell-small-header">
                            <svg className="sell-rectangle" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" fill="var(--yellow)"/>
                            </svg>
                            <h4 className="sell-small-header-text">Sell</h4>
                        </Col>   
                    </Row>
                    <Row>
                        <Col className="sell-header-text">
                            <h2 className="sell-header">Dtail Spaces</h2> 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className="sell-text-description sell-text-description-first">Present, impress and collaborate with customers in flexible and dynamic 3D environments built by your own teams without 3D expertise and leaving the Dtail platform.</p>
                        </Col>   
                    </Row>
                    <Row>
                    </Row>
                    <Row>
                        <Col>
                            <p className="sell-text-description sell-text-description-second">Invite customers to view your 3D showroom in either a guided or unsupervised experience – no installation or specific hardware required.</p>
                        </Col>   
                    </Row>
                    <Row>
                        <Col>
                            <Button className="button" href="./contacts"><span className="sell-button-name">Contact us</span><img src={Arrow} className="arrow sell-arrow" alt=""></img></Button>
                        </Col>  
                    </Row>
                </Col>
            </Row>
            <Row><hr size="1" width="100%" color="white" id="line"></hr></Row>
            <Row>
            <Col align="center" md={12} lg={12} className="action-container-inner">
                <div className="action-text-container"><h3 className="action-text action-text-first">How does it work?</h3></div>
                <Button className="button" href="./platform"><span className="action-button-text-first">Explore the platform</span><img src={Arrow} className="arrow action-arrow-first" alt=""></img></Button>
            </Col> 
            </Row>      
        </Container>
    </div>   
     
    )
}
export default Brand;